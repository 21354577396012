import { useAnalytics } from "@/hooks/useAnalytics";
import styles from "@/styles/404.module.scss";
import { Button, Flex, Heading, Text } from "@talent-garden/react-components";
import type { GetStaticProps } from "next";
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { NextSeo } from "next-seo";

const Custom404 = () => {
  const { t } = useTranslation("404");
  const router = useRouter();
  const { setPageView } = useAnalytics();

  useEffect(() => {
    if (!setPageView) {
      return;
    }

    setPageView({
      event_category: "error",
      event_label: "404",
    });
  }, [setPageView]);

  return (
    <>
      <NextSeo noindex nofollow title={t("title") as string} />
      <Flex
        horizontalAlign="center"
        verticalAlign="center"
        className={styles.container}
        horizontalPadding={32}
        verticalPadding={32}
      >
        <Flex
          className={styles.content}
          direction="column"
          horizontalPadding={24}
          verticalPadding={32}
          verticalAlign="start"
          horizontalAlign="center"
          rowGap={32}
        >
          <Heading variant="xl" weight="bold" textAlign="center">
            {t("title")}
          </Heading>

          <Text variant="m" textAlign="center">
            {t("description")}
          </Text>

          <Flex
            className={styles.btnsWrapper}
            direction="column"
            horizontalAlign="center"
            verticalAlign="center"
            rowGap={16}
            columnGap={16}
          >
            <Button
              kind="secondary"
              dimension="medium"
              icon="arrow_back"
              iconVariant="outlined"
              fullWidth
              onClick={() => router.back()}
              capitalize={false}
            >
              {t("go-back")}
            </Button>
            <Link href="/welcome">
              <Button
                fullWidth
                kind="primary"
                dimension="medium"
                capitalize={false}
              >
                {t("go-home")}
              </Button>
            </Link>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default Custom404;

export const getStaticProps: GetStaticProps = async ({ locale = "en" }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale, ["404"])),
    },
  };
};
